export const EDITOR_PAGE_LOADEDUSUARIO = "EDITOR_PAGE_LOADEDUSUARIO";
export const EDITOR_PAGE_UNLOADEDUSUARIO = "EDITOR_PAGE_UNLOADEDUSUARIO";
export const USUARIO_SUBMITTED = "USUARIO_SUBMITTED";
export const ADD_TAGUSUARIO = "ADD_TAGUSUARIO";
export const REMOVE_TAGUSUARIO = "REMOVE_TAGUSUARIO";
export const UPDATE_FIELD_EDITORUSUARIO = "UPDATE_FIELD_EDITORUSUARIO";
export const USUARIO_PAGE_LOADED = "USUARIO_PAGE_LOADED";
export const USUARIO_PAGE_UNLOADED = "USUARIO_PAGE_UNLOADED";
export const ADD_USRUPDATE = "ADD_USRUPDATE";
export const DELETE_USRUPDATE = "DELETE_USRUPDATE";
export const DELETE_USUARIO = "DELETE_USUARIO";
export const USUARIO_FAVORITED = "USUARIO_FAVORITED";
export const USUARIO_UNFAVORITED = "USUARIO_UNFAVORITED";
export const USUARIO_SERVEACTED = "USUARIO_SERVEACTED";
export const SET_PAGEUSUARIO = "SET_PAGEUSUARIO";
export const APPLY_TAG_FILTERUSUARIO = "APPLY_TAG_FILTERUSUARIO";
export const HOME_PAGE_LOADEDUSUARIO = "HOME_PAGE_LOADEDUSUARIO";
export const HOME_PAGE_UNLOADEDUSUARIO = "HOME_PAGE_UNLOADEDUSUARIO";
export const CHANGE_TABUSUARIO = "CHANGE_TABUSUARIO";
export const PROFILE_PAGE_LOADEDUSUARIO = "PROFILE_PAGE_LOADEDUSUARIO";
export const PROFILE_PAGE_UNLOADEDUSUARIO = "PROFILE_PAGE_UNLOADEDUSUARIO";
export const PROFILE_FAVORITES_PAGE_LOADEDUSUARIO = "PROFILE_FAVORITES_PAGE_LOADEDUSUARIO";
export const PROFILE_FAVORITES_PAGE_UNLOADEDUSUARIO = "PROFILE_FAVORITES_PAGE_UNLOADEDUSUARIO";
const expd = ({
    EDITOR_PAGE_LOADEDUSUARIO,
    EDITOR_PAGE_UNLOADEDUSUARIO,
    USUARIO_SUBMITTED,
    ADD_TAGUSUARIO,
    REMOVE_TAGUSUARIO,
    UPDATE_FIELD_EDITORUSUARIO,
    USUARIO_PAGE_LOADED,
    USUARIO_PAGE_UNLOADED,
    ADD_USRUPDATE,
    DELETE_USRUPDATE,
    DELETE_USUARIO,
    USUARIO_FAVORITED,
    USUARIO_UNFAVORITED,
    SET_PAGEUSUARIO,
    APPLY_TAG_FILTERUSUARIO,
    HOME_PAGE_LOADEDUSUARIO,
    HOME_PAGE_UNLOADEDUSUARIO,
    CHANGE_TABUSUARIO,
    PROFILE_PAGE_LOADEDUSUARIO,
    USUARIO_SERVEACTED,
    PROFILE_PAGE_UNLOADEDUSUARIO,
    PROFILE_FAVORITES_PAGE_LOADEDUSUARIO,
    PROFILE_FAVORITES_PAGE_UNLOADEDUSUARIO,
});






export default expd;