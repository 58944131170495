export const EDITOR_PAGE_LOADEDUPLOAD = "EDITOR_PAGE_LOADEDUPLOAD";
export const EDITOR_PAGE_UNLOADEDUPLOAD = "EDITOR_PAGE_UNLOADEDUPLOAD";
export const UPLOAD_SUBMITTED = "UPLOAD_SUBMITTED";
export const ADD_TAGUPLOAD = "ADD_TAGUPLOAD";
export const REMOVE_TAGUPLOAD = "REMOVE_TAGUPLOAD";
export const UPDATE_FIELD_EDITORUPLOAD = "UPDATE_FIELD_EDITORUPLOAD";
export const UPLOAD_PAGE_LOADED = "UPLOAD_PAGE_LOADED";
export const UPLOAD_PAGE_UNLOADED = "UPLOAD_PAGE_UNLOADED";
export const ADD_UPLINK = "ADD_UPLINK";
export const DELETE_UPLINK = "DELETE_UPLINK";
export const DELETE_UPLOAD = "DELETE_UPLOAD";
export const UPLOAD_FAVORITED = "UPLOAD_FAVORITED";
export const UPLOAD_SERVEACTED = "UPLOAD_SERVEACTED";
export const SET_PAGEUPLOAD = "SET_PAGEUPLOAD";
export const APPLY_TAG_FILTERUPLOAD = "APPLY_TAG_FILTERUPLOAD";
export const HOME_PAGE_LOADEDUPLOAD = "HOME_PAGE_LOADEDUPLOAD";
export const HOME_PAGE_UNLOADEDUPLOAD = "HOME_PAGE_UNLOADEDUPLOAD";
export const CHANGE_TABUPLOAD = "CHANGE_TABUPLOAD";
export const PROFILE_PAGE_LOADEDUPLOAD = "PROFILE_PAGE_LOADEDUPLOAD";
export const PROFILE_PAGE_UNLOADEDUPLOAD = "PROFILE_PAGE_UNLOADEDUPLOAD";
export const PROFILE_FAVORITES_PAGE_LOADEDUPLOAD = "PROFILE_FAVORITES_PAGE_LOADEDUPLOAD";
export const PROFILE_FAVORITES_PAGE_UNLOADEDUPLOAD = "PROFILE_FAVORITES_PAGE_UNLOADEDUPLOAD";

const expd = ({
    EDITOR_PAGE_LOADEDUPLOAD,
    EDITOR_PAGE_UNLOADEDUPLOAD,
    UPLOAD_SUBMITTED,
    ADD_TAGUPLOAD,
    REMOVE_TAGUPLOAD,
    UPDATE_FIELD_EDITORUPLOAD,
    UPLOAD_PAGE_LOADED,
    UPLOAD_PAGE_UNLOADED,
    ADD_UPLINK,
    DELETE_UPLINK,
    DELETE_UPLOAD,
    UPLOAD_FAVORITED,
    UPLOAD_SERVEACTED,
    SET_PAGEUPLOAD,
    APPLY_TAG_FILTERUPLOAD,
    HOME_PAGE_LOADEDUPLOAD,
    HOME_PAGE_UNLOADEDUPLOAD,
    CHANGE_TABUPLOAD,
    PROFILE_PAGE_LOADEDUPLOAD,
    PROFILE_PAGE_UNLOADEDUPLOAD,
    PROFILE_FAVORITES_PAGE_LOADEDUPLOAD,
    PROFILE_FAVORITES_PAGE_UNLOADEDUPLOAD,
});





export default expd;